<template>
    <div class="tech-warp">
       <erji-carousel></erji-carousel>
       <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 在职培训 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                <p>
                   在职培训业务概况
                </p>
            </div>
       </div>
      <div class="yz-img-con">
         <img src="../../assets/images/train-back.png">
      </div>
        <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 业务培训 ——</p>
            </div>
       </div>
      <div class="yz-item-img">
         <div class="gov-item">
            <img src="../../assets/images/train01.jpg">
            <p class="desc-gov">新职业培训</p>
         </div>
        <div class="gov-item">
            <img src="../../assets/images/train02.jpg">
            <p class="desc-gov">涉外培训</p>
        </div>
        <div class="gov-item">
           <img src="../../assets/images/train03.jpg">
           <p class="desc-gov">企业培训</p>
        </div>
      </div>
    </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" >
   .ab-text{
           margin:40px 0;
           .ab-title{
               text-align: center;
               font-size: 30px;
               color:#0184d2;
           }
           .ab-desc{
               margin-top:10px;
               text-align: center;
               font-size: 14px;
               color: #656d78;
           }
   }
   .yz-img-con{
      width:1200px;
      margin:0 auto;

     img{
       width: auto;
       object-fit:none;
       margin-bottom: 30px;
     }
   }
   .yz-item-img{
       width:1200px;
       margin:0 auto;
       display: flex;
        justify-content: center;/*水平居中*/
		    align-items: center;/*垂直居中*/
       .gov-item{
         width: 30%;
         height:350px;
         margin:15px;
         img{
           margin-bottom: 30px;
           width: 100%;
           height: 270px;
         }
         .title-gov{
           margin-top:20px;
           font-size:20px;
         }
         .desc-gov{
           font-size: 16px;
           line-height: 30px;
         }
       }
     }
</style>
